:root {
  --primary-color: #1e2128;
  --secondary-color: #ffc107;
  --white-color: #ffffff;
  --gray-color: #f9f9f9;
  --black-color: #000000;
  --slaty-color: #55585f;
  --purple-color: #6d31ed;
  --text-color: #171a1f;
  --accent-color: #4cf23e;
}

* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.mobile-header-section {
  display: none;
}

.blank-space {
  height: 10px;
  width: 10px;
}

button {
  cursor: pointer;
}

.logo,
.menu > .menu-items {
  cursor: pointer;
}

.manage-relation-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-container {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.mobile-header-container {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.manage-graphics-container {
  position: relative;
}

.left-section {
  display: flex;
  width: 100%;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  padding-left: 20%;
  gap: 23px;
}

.menu-items {
  color: var(--white-color);
}

.login-btn {
  font-family: 'Manrope';
  background-color: var(--slaty-color);
  color: var(--white-color);
  padding: 10px 50px;
  border: none;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.hero-section-subText {
  font-size: 23px;
  line-height: 42px;
  color: #fff8f8;
  text-align: center;
  padding: 30px 20px;
  margin-top: 8vh;
}

.hero-main-container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding-left: 7%;
}

.hero-image-section {
  display: flex;
  gap: 10px;
}
.text-container {
  margin-right: 5%;
}

.hero-image {
  max-width: 100%;
  height: auto;
}

.hero-container {
  background: var(--primary-color) url('/public/wave.svg') no-repeat center center / cover;

  /* min-height: 100vh; */
  /* display: flex; */
  /* padding-left: 100px; */
  /* padding-right: 20px; */
  /* align-items: flex-start; */
  /* justify-content: space-around; */
}

.hero-container2 {
  background: url('/src/assets/wave2.svg') no-repeat bottom center / contain;

  /* min-height: 100vh; */
  /* display: flex; */
  /* padding-left: 100px; */
  /* padding-right: 20px; */
  /* align-items: flex-start; */
  /* justify-content: space-around; */
}

.hero-text-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 80px;
  line-height: 104px;
  font-weight: 400;
  color: var(--white-color);
}

.hero-title {
  font-size: 80px;
  line-height: 104px;
  font-weight: 400;
  color: var(--white-color);
}

.hero-left-section {
  padding-top: 50px;
}

.highlighted-title {
  background-color: var(--purple-color);
  padding: 0px 15px;
  font-weight: 700;
  border-radius: 40px;
  text-shadow: 10px 7px 6px rgba(0, 0, 0, 0.25);
}

.description {
  font-size: 24px;
  line-height: 36px;
  color: var(--white-color);
  padding-top: 30px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
  overflow-wrap: break-word;
}

.get-started-btn,
.hero-container2 .get-started-btn {
  font-family: 'Manrope';
  margin-top: 40px;
  padding: 10px 30px;
  color: var(--text-color);
  font-size: 18px;
  line-height: 28px;
  border: none;
  background: var(--white-color);
  border-radius: 4px;
}

.hero-container2 .get-started-btn {
  background: var(--black-color);
  color: var(--white-color);
  border-radius: 4px;
}

.right-section {
  display: flex;
  gap: 10px;
}

.brand-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #6f7787;
  text-align: center;
}

.brand-container {
  /* padding-left: 100px; */
  /* padding-right: 100px; */
  padding-top: 20px;
}

.card-section {
  margin-top: 20px;
  text-align: center;
}

.brand-image {
  height: 100px;
  /* min-width: 24vw; */
  width: 100px;
  border-radius: 50%;
  background-color: #d3c1fa;
  display: inline;
  object-fit: cover;
}

.brand-user-name {
  font-family: 'Lexend';
  font-size: 1.7vh;
  /* line-height: 30px; */
  font-weight: 400;
  color: var(--text-color);
}

.brand-hash-tags {
  font-size: 16px;
  line-height: 26px;
  color: #9095a1;
}

.brand-icon-with-follower-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.follower-title {
  font-size: 14px;
  line-height: 22px;
  color: var(--text-color);
}

.brand-card-container {
  display: flex;
  justify-content: space-around;
}

.brand-logo-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}
.brand-card {
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 8px 1px 15px 1px rgba(0.1, 0.1, 0.1, 0.1); */
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  margin: 0px 12px 0px 12px;
  padding: 20px 15px 20px 15px;
  min-width: 160px;
}

.one-platform-section {
  background: #f3f1ef;
}

.discover-container,
.ai-discover-container,
.manage-relationship-container,
.one-platform-container,
.connect-brand-container {
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.one-platform-container,
.connect-brand-container {
  margin-top: auto;
  height: 100%;
  align-items: center;
}

.one-platform-left,
.connect-brand-left {
  flex: 1;
  max-width: 1438px;
}

.one-platform-right,
.connect-brand-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.discover-title,
.one-platform-title,
.connect-brand-title {
  font-family: 'Lexend';
  font-size: 32px;
  font-weight: 700;
  color: #323743;
  /* position: relative; */
}

.one-platform-title,
.connect-brand-title {
  font-size: 2.145rem;
}

.discover-highlighted-title,
.one-platform-highlighted-text,
.connect-brand-highlighted-text {
  font-family: 'Lexend';
  padding: 0px 8px;
  background-color: var(--black-color);
  color: var(--white-color);
  white-space: nowrap;
}

.discover-description {
  font-size: 2vh;
  line-height: 25px;
  color: var(--text-color);
}

.ai-graphics-container {
  position: relative;
}

.discover-description-container {
  display: flex;
  align-items: center;

  gap: 15px;
}

.checkbox-container {
  margin-top: 7%;
}

.one-platform-btn,
.connect-brand-btn,
.next-colab-btn {
  /* background-color: #000000 !important; */
  height: 52px;
  padding: 14px 18px;
  border-radius: 4px;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: var(--white-color);
}

.next-colab-btn {
  width: 134px;
  border-radius: 5rem !important;
}

.discover-btn-container {
  margin-left: 5%;
  margin-top: 7%;
}

.ai-outline-btn {
  font-family: 'Manrope';
  border: none;
  padding: 10px 20px;
  border: 1px solid var(--purple-color);
  color: var(--purple-color);
  font-size: 18px;
  line-height: 28px;
  background-color: var(--white-color);
  border-radius: 4px;
}

.discover-outline-btn {
  font-family: 'Manrope';
  border: none;
  padding: 10px 20px;
  border: 1px solid var(--purple-color);
  color: var(--purple-color);
  font-size: 18px;
  line-height: 28px;
  background-color: var(--white-color);
  border-radius: 4px;
}

.graphics {
  /* position: absolute;
  top: -85%;
  left: -25%; */
}

.checkbox-margin {
  margin-top: 3%;
}

.next-colab-section {
  background-color: var(--accent-color);
}

.contact-us-container {
  background-color: #1e2128;
  /* padding-left: 100px; */
  /* padding-right: 100px; */
  /* margin-top: 5%; */
}

.contact-us-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-container {
  display: flex;
  gap: 20px;
}

.contact-us-title {
  font-family: 'Lexend';
  font-size: 32px;
  line-height: 48px;
  color: var(--white-color);
}

.input-container {
  display: flex;
  gap: 10px;
}

.input-section {
  border: 1px solid #6f7787;
  background-color: #323743;
  min-width: 300px;
  padding: 0px 20px;
  border-radius: 4px;
  color: #bdc1ca;
  height: 51px;
}

.input-section::placeholder {
  font-family: 'Manrope';
  color: #bdc1ca;
  font-size: 18kpx;
}

.contact-us-btn {
  font-family: 'Manrope';
  background-color: var(--white-color);
  padding: 0px 30px;
  border-radius: 4px;
  color: var(--text-color);
  font-size: 18px;
  line-height: 28px;
  border: none;
  height: 52px;
}

.social-icons {
  width: 70px;
  height: 70px;
}

.footer-container {
  background-color: var(--purple-color);
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 200px;
  align-items: center;
}

.address-title {
  font-size: 32px;
  line-height: 48px;
  color: var(--white-color);
  font-family: 'Lexend';
}

.address-subtitle {
  font-size: 14px;
  line-height: 30px;
  color: var(--white-color);
}

.menu-section ul,
.about-us-section ul,
.privacy-section ul {
  font-size: 14px;
  line-height: 25px;
  color: var(--white-color);
  list-style-type: none;
}

.social-connect-section {
  display: flex;
  gap: 10px;
}

.graphics-container {
  padding-left: 5px;
  /* position: relative; */
}

.manage-graphics {
  position: absolute;
  top: 0%;
  right: -50%;
}

.responsive-layout,
.rl-brand-card-container,
.rl-footer-subtitle-container,
.rl-footer-menu-container,
.rl-menu {
  display: none;
}

.coming-container {
  background: #0c0f0a;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming-soon-title {
  font-size: 16px;
  line-height: 26px;
  color: var(--white-color);
  text-align: center;
}

.about-us-section ul > li,
.privacy-section ul > li,
.menu-section ul > li {
  font-size: 14px;
  line-height: 25px;
}

.discover-section-graphics {
  width: 100%;
  height: 100%;
}

.discover-graphics-container {
  position: relative;
}

.discover-left-section {
  padding: 20px;
  /* position: relative; */
}

.manage-graphic-image {
  width: 70px;
  height: 30px;
  padding-left: 5%;
}

input:focus {
  outline: none;
}

.mobile-break {
  display: none;
}

/* responsive layout css for mobile screens  */
@media (max-width: 768px) {
  .mobile-break {
    display: block;
  }
  .brand-title {
    font-size: 22px;
  }
  .brand-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .header-container,
  .hero-main-container {
    display: none;
  }

  .mobile-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #423d4f; */
  }

  .logo {
    display: none;
  }

  .social-icons {
    height: 44px;
    width: 56px;
  }

  .responsive-layout {
    display: block;
    width: 100%;
    align-items: center;
  }

  .input-section::placeholder {
    font-family: 'Manrope';
    color: #bdc1ca;
    font-size: 10px;
  }

  .ai-discover-container {
    display: grid;
  }

  .hero-container {
    background-image: url('./assets/Hero-Mobile.svg');
    background-position: center;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    display: block;
    align-items: inherit;
    justify-content: inherit;
  }

  .rl-title,
  .rl-highlighter {
    font-size: 70px;
    line-height: 104px;
    font-weight: 400;
    color: var(--white-color);
    text-align: center;
    z-index: 99;
    opacity: 0.63;
    filter: brightness(150%);
  }

  .rl-highlighter {
    font-weight: 700;
    opacity: 0.88;
  }

  .rl-container {
    background-color: rgba(109, 49, 237, 0.55);
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rl-btn-container {
    font-family: 'Manrope';
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .rl-btn {
    padding: 15px 60px;
    color: var(--text-color);
    font-size: 18px;
    line-height: 28px;
    border: none;
    outline: none;
  }

  .discover-highlighted-title {
    font-size: 3vh;
    line-height: 28px;
  }

  .discover-highlighted-title,
  .one-platform-highlighted-text,
  .connect-brand-highlighted-text {
    white-space: wrap;
  }

  .menu,
  .right-section,
  .description,
  .get-started-btn,
  .title,
  .brand-card-container,
  .contact-us-image,
  .address-subtitle,
  .menu-section,
  .about-us-section,
  .privacy-section,
  .hero-image-section,
  .hero-text-section,
  .rl-manage-partnership,
  .manage-relationship-container {
    display: none;
  }

  /* brand section  */
  .brand-container {
    /* padding-left: 20px; */
    /* padding-right: 20px; */
  }

  .rl-brand-card-container {
    display: flex;
    justify-content: space-around;
    overflow-x: scroll;
  }

  /* discover creator section  */
  .discover-container {
    display: block;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
  }

  .discover-left-section {
    margin-top: 5%;
    padding-left: 2%;
    /* position: relative; */
    /* padding: 20px; */
  }

  .discover-title {
    font-size: 3vh;
    /* position: relative; */
  }

  .discover-description {
    font-size: 2vh;
    line-height: 25px;
    margin-top: 5%;
  }

  .discover-outline-btn {
    font-family: 'Manrope';
    font-size: 10px;
    line-height: 13px;
    outline: none;
    border: none;
    background-color: transparent;
  }

  .ai-outline-btn {
    font-family: 'Manrope';
    font-size: 10px;
    line-height: 13px;
    outline: none;
    background-color: var(--white-color);
  }

  .discover-right-section {
    margin-top: 3%;
    order: 1;
  }

  .one-platform-container,
  .connect-brand-container {
    flex-direction: column;
  }

  .one-platform-right,
  .connect-brand-right {
    margin-top: 0.5rem;
  }

  /* contact us css  */
  .contact-us-title {
    font-size: 16px;
    line-height: 27px;
    text-align: end;
  }

  .input-container {
    display: block;
  }

  .input-section {
    min-width: 150px;
    height: 30px;
    border-color: #6f7787;
    border-radius: 2.25px;
    border-width: 0.56px;
    margin-top: 15px;
  }

  .contact-us-btn {
    font-family: 'Manrope';
    font-size: 10px;
    line-height: 15px;
    height: 30px;
    border-radius: 0px;
    padding: 7px 14px;
  }

  .contact-us-container {
    /* padding-left: 20px; */
    /* padding-right: 20px; */
  }

  .rl-contact-us-btn-container {
    font-family: 'Manrope';
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;
  }

  .rl-input-section {
    display: flex;
    justify-content: end;
  }

  /* footer class  */
  .footer-container {
    display: block;
    padding-left: 20px;
    padding-right: 10px;
  }

  .rl-footer-menu-container {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 3%;
  }

  .rl-footer-menu-container ul > li {
    list-style-type: none;
  }

  .social-connect-section {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
  }

  .address-title {
    text-align: center;
  }

  .rl-footer-subtitle-container {
    display: block;
    text-align: center;
  }

  .rl-address-subtitle {
    font-size: 14px;
    line-height: 30px;
    color: var(--white-color);
  }

  .rl-menu {
    display: block;
  }

  .rl-menu-section,
  .rl-about-us-section,
  .rl-privacy-section ul {
    list-style: none;
    font-size: 14px;
    line-height: 25px;
    color: var(--white-color);
  }

  .mobile-header-logo {
    width: 28vw;
    max-width: fit-content;
    padding-top: 2px;
  }

  .header-logo {
    width: 80px;
    height: 40px;
  }

  .discover-section-graphics {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  .manage-graphic-image {
    width: 20px;
    height: 15px;
  }

  .manage-graphics {
    right: -2%;
  }
}

/* Target screens between 600px and 900px (iPads in portrait mode, some tablets) */
@media (min-width: 600px) and (max-width: 1024px) {
  .image2 {
    display: none;
  }

  .menu {
    padding-left: 5%;
    gap: 20px;
  }

  .discover-section-graphics {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  /* Styles for small screens (iPads/tablets) */
  .hero-main-image {
    width: 20%;
    height: auto;
  }

  .hero-image {
    max-width: 100%;
    height: auto;
  }

  .hero-image-section {
    gap: 5px;
  }

  .description {
    font-size: 22px;
  }

  .hero-text-section {
    padding-left: 0;
    padding-top: 0;
  }

  .hero-main-container {
    padding-left: 2%;
  }

  .contact-us-title {
    font-size: 25px;
  }

  .input-section {
    /* padding: 11px; */
    min-width: 200px;
    height: 40px;
  }

  .contact-us-btn {
    font-family: 'Manrope';
    padding: 0px 16px;
    font-size: 14px;
    line-height: 22px;
    height: 41px;
  }

  .social-icons {
    width: 35px;
    height: 35px;
  }

  .footer-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .address-title {
    font-size: 30px;
  }

  .hero-main-container {
    justify-content: space-around;
  }

  .graphics {
    top: -90%;
    left: -30%;
    /* left: -9%; */
  }

  .manage-graphics {
    right: -10%;
  }

  .discover-title {
    font-size: 27px;
  }

  .brand-image {
    height: 8vh;
    /* width: 80px; */
    display: inline;
  }

  .brand-user-name {
    font-size: 1.7vh;
    /* line-height: 28px; */
  }

  .brand-hash-tags {
    font-size: 14px;
    line-height: 24px;
  }
}
